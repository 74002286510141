import { createSlice } from "@reduxjs/toolkit";
import { APIS } from "../../utils/constants";

const sourceSlice = createSlice({
  name: "source",
  initialState: {
    sources: [
      { id: APIS.PARIMATCH, name: "Parimatch" },
      { id: APIS.SPORTSRADAR, name: "SportsRadar" },
      { id: APIS.SPORTSRAPI, name: "SportsApi" },
      { id: APIS.BLASKAPI, name: "BlaskApi" },
      { id: APIS.INTERNAL, name: "Internal" },
    ],
    currentSource: null,
  },
  reducers: {
    setCurrentSource(state, action) {
      state.currentSource = action.payload;
    },
  },
});

export default sourceSlice.reducer;
export const { setCurrentSource } = sourceSlice.actions;
