import { applyQueryParams } from "../utils/request";
import instance from "./api";
import { execute } from "./response";

const endpoints = {
  competitors: `${process.env.REACT_APP_API_URL}/api/competitors`,
  competitor: (id) => `${process.env.REACT_APP_API_URL}/api/competitors/${id}`,
};

const fetchAllCompetitors = async (params) => {
  const queryParams = params
    ? params.map((x) => `${x.key}=${x.value}`).join("&")
    : "";
  const res = await instance.get(
    `${endpoints.competitors}${queryParams ? `?${queryParams}` : ""}`
  );
  return res.data;
};

const postCompetitor = async (data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitors`;
  return execute(() => instance.post(applyQueryParams(endpoint), data));
};

const putCompetitor = async (id, data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitors/${id}`;
  return execute(() => instance.put(applyQueryParams(endpoint), data));
};

const deleteCompetitor = async (id) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitors/${id}`;
  return execute(() => instance.delete(applyQueryParams(endpoint)));
};

const addCompetitorShortName = (id, name) => {
  return instance.put(endpoints.competitor(id), name);
};

export { fetchAllCompetitors, addCompetitorShortName, postCompetitor, putCompetitor, deleteCompetitor };
