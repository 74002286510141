import { TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  deleteCompetitor,
  fetchAllCompetitors,
  postCompetitor,
  putCompetitor,
} from "../../../api/competitors";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { APIS, PERMISSIONS, SPORTS } from "../../../utils/constants";
import hasPermissions from "../../../utils/hasPermissions";
import ManageCompetitorDialog from "../manageCompetitorDialog";
import ConfirmationDialog from "../../common/confirmationDialog";

const createColumns = (handleOpenDialog, handleOpenConfirmation) => {
  return [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "abbreviation",
      headerName: "Short Name",
      width: 150,
      sortable: false,
    },
    {
      field: "country",
      headerName: "Country",
      width: 200,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 200,
    },
    {
      field: "isDeactivated",
      headerName: "Deactivated",
      width: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (row) => (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_COMPETITORS)}
            onClick={() => handleOpenDialog(row.row)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_COMPETITIONS)}
            onClick={() => handleOpenConfirmation(row.row)}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];
};

export function CompetitorsTab() {
  const [competitors, setCompetitors] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [activeCompetitor, setActiveCompetitor] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sortOptions, setSortOptions] = useState([
    { field: "name", sort: "asc" },
  ]);

  const [filters, setFilters] = useState({
    id: "",
    sport: "",
    name: "",
    country: "",
    urlSegment: "",
  });

  const [tempFilters, setTempFilters] = useState({
    id: "",
    sport: "",
    name: "",
    country: "",
    urlSegment: "",
  });

  const handleOpenDialog = useCallback((item) => {
    setActiveCompetitor(item);
    setIsDialogOpen(true);
  }, []);

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      filterParams.push({ key, value: filters[key] });
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sortParams = [
      { key: "sortBy", value: sortOptions?.[0]?.field || "name" },
      { key: "sortDirection", value: sortOptions?.[0]?.sort || "asc" },
    ];
    const sourceParams = [
      { key: "source", value: APIS.INTERNAL },
      { key: "sportId", value: SPORTS.CRICKET },
    ];
    return [...filterParams, ...pageParams, ...sortParams, ...sourceParams];
  }, [filters, page, pageSize, sortOptions]);

  const fetchCompetitorsList = useCallback(async () => {
    const queryParams = createParams();
    const competitors = await fetchAllCompetitors(queryParams);
    setCompetitors(competitors.data);
    setTotal(competitors.total);
  }, [createParams]);

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const handleSortModelChange = useCallback((sortModel) => {
    setPage(0);
    setSortOptions([...sortModel]);
  }, []);

  const handleResetFilters = useCallback(async () => {
    setFilters({
      sport: "",
      name: "",
      country: "",
      id: "",
      urlSegment: "",
    });
    setTempFilters({
      sport: "",
      name: "",
      country: "",
      id: "",
      urlSegment: "",
    });
    setPage(0);
    await fetchCompetitorsList();
  }, [fetchCompetitorsList]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  const handleOpenConfirmation = useCallback((item) => {
    setActiveCompetitor(item);
    setIsConfirmationOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
    setIsConfirmationOpen(false);
    setActiveCompetitor(null);
  }, []);

  const handleAddCompetitor = useCallback(async (competitor) => {
    await postCompetitor(competitor);
    await fetchCompetitorsList();
    handleClose();
  }, [handleClose, fetchCompetitorsList]);

  const handleEditCompetitor = useCallback(async (competitor) => {
    const data = competitor;
    const id = competitor.id;
    await putCompetitor(id, data);
    await fetchCompetitorsList();
    handleClose();
  }, [handleClose, fetchCompetitorsList]);

  const handleConfirm = useCallback((competitor) => {
    if (competitor.id) {
      return handleEditCompetitor(competitor);
    }
    handleAddCompetitor(competitor);
  }, [handleAddCompetitor, handleEditCompetitor]);

  const handleDeleteConfirmation = useCallback(async () => {
    await deleteCompetitor(activeCompetitor.id);
    await fetchCompetitorsList();
    handleClose();
  }, [fetchCompetitorsList, handleClose, activeCompetitor]);

  useEffect(() => {
    async function fetchData() {
      await fetchCompetitorsList();
    }

    fetchData();
  }, [createParams, fetchCompetitorsList]);

  const columns = useMemo(() => {
    return createColumns(handleOpenDialog, handleOpenConfirmation);
  }, [handleOpenDialog, handleOpenConfirmation]);

  return (
    <Box sx={{ textAlign: "center" }}>
      <h1>Competitors</h1>
      <Button
        variant="contained"
        color="primary"
        disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_COMPETITORS)}
        onClick={() => handleOpenDialog()}
      >
        Add new competitor
      </Button>
      {isConfirmationOpen && <ConfirmationDialog
        open={isConfirmationOpen}
        title={"Confirm"}
        content={"Do you want to delete this competition?"}
        onClose={handleClose}
        onConfirm={handleDeleteConfirmation}
      />}
      {isDialogOpen && <ManageCompetitorDialog open={isDialogOpen} onConfirm={handleConfirm} onClose={handleClose} competitor={activeCompetitor} />}
      {competitors && (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "12px",
              padding: "20px 0",
            }}
          >
            filter by
            {/* <TextField
              name="id"
              variant="outlined"
              size="small"
              label="ID"
              value={tempFilters.id}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="sport"
              variant="outlined"
              size="small"
              label="Sport"
              value={tempFilters.sport}
              onChange={(event) => handleFiltersChange(event)}
            /> */}
            <TextField
              name="name"
              variant="outlined"
              size="small"
              label="Name"
              value={tempFilters.name}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="country"
              variant="outlined"
              size="small"
              label="Country"
              value={tempFilters.country}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="urlSegment"
              variant="outlined"
              size="small"
              label="Url"
              value={tempFilters.urlSegment}
              onChange={(event) => handleFiltersChange(event)}
            />
            <Button variant="contained" onClick={() => handleSearchClick()}>
              Search
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleResetFilters()}
            >
              Reset
            </Button>
          </Box>
          <Box sx={{ height: "700px", width: "100%" }}>
            <DataGrid
              rows={competitors}
              columns={columns}
              rowCount={total}
              pageSize={pageSize}
              page={page}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[25, 50, 100]}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick
              disableColumnFilter
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
