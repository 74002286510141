import { applyQueryParams } from "../utils/request";
import instance from "./api";
import { execute } from "./response";

const getCompetitions = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitions`;
  return execute(() => instance.get(applyQueryParams(endpoint, params)));
};

const postCompetition = async (data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitions`;
  return execute(() => instance.post(applyQueryParams(endpoint), data));
};

const putCompetition = async (id, data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitions/${id}`;
  return execute(() => instance.put(applyQueryParams(endpoint), data));
};

const deleteCompetition = async (id) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitions/${id}`;
  return execute(() => instance.delete(applyQueryParams(endpoint)));
};

const getAllCompetitionsByLeague = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitions`;
  const res = await instance.get(
    applyQueryParams(endpoint, [...params, {
      key: 'take',
      value: 10_000
    }, {
      key: 'skip',
      value: 0
    }])
  );
  return res.data?.data;
};

export { getAllCompetitionsByLeague, getCompetitions, postCompetition, putCompetition, deleteCompetition };
