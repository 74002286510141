import axios from 'axios';
import { LOGIN_URL } from '../utils/constants';

const instance = axios.create({});

instance.interceptors.request.use(config => {
  const localToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
  config.headers = config.headers || {};
  config.headers["Content-Type"] = "application/json";
  if (localToken) {
    config.headers.Authorization = `Bearer ${localToken}`;
  }
  return config;
});

instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  switch (error.response.status) {
    case 401:
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      window.location = LOGIN_URL;
      throw new Error('Unauthorized');
    case 403:
      window.location = '/';
      break;
    default:
      throw error;
  }
});

export default instance;
