import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { fetchLeagues } from '../../../api/leagues';
import { APIS, SPORTS } from '../../../utils/constants';

const leagueParams = [{ key: 'sportId', value: SPORTS.CRICKET }, { key: 'source', value: APIS.INTERNAL }]

const ManageCompetitionDialog = ({ open, onConfirm, onClose, competition }) => {
  const [leagues, setLeagues] = useState([]);
  const [league, setLeague] = useState(null);
  const [tempCompetition, setTempCompetition] = useState(competition ||
  {
    name: '',
    leagueId: '',
    // currentSeason: {
    //   name: '',
    //   startDate: '',
    //   endDate: ''
    // }
  });

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setTempCompetition({ ...tempCompetition, [name]: value });
  }, [tempCompetition]);

  // const handleChangeSeason = useCallback((e) => {
  //   const { name, value } = e.target;
  //   setTempCompetition({ ...tempCompetition, currentSeason: { ...tempCompetition.currentSeason, [name]: value } });
  // }, [tempCompetition]);

  const handleChangeLeague = useCallback((league) => {
    setLeague(league);
    setTempCompetition({ ...tempCompetition, leagueId: league.id });
  }, [setLeague, tempCompetition]);

  const fetchLeagueList = useCallback(async () => {
    const leagues = await fetchLeagues(leagueParams);
    if (competition) {
      const activeLeague = leagues.data.find((item) => item.id === competition.leagueId);
      setLeague(activeLeague);
    }
    setLeagues(leagues.data);
  }, [competition]);

  const isDisabled = useMemo(() => {
    return !tempCompetition.name || !tempCompetition.leagueId
    // !tempCompetition.currentSeason.name ||
    // !tempCompetition.currentSeason.startDate ||
    // !tempCompetition.currentSeason.endDate
  }, [tempCompetition]);

  useEffect(() => {
    fetchLeagueList();
  }, [fetchLeagueList]);

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{(competition && competition.name) || 'New competition'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', minWidth: '450px', minHeight: '200px' }}>
          <TextField
            name="name"
            variant="outlined"
            size="small"
            label="Name"
            value={tempCompetition.name}
            onChange={handleChange}
            sx={{ marginTop: '10px' }}
          />
          <Autocomplete
            disablePortal
            options={leagues}
            getOptionLabel={(option) => option.name}
            value={league}
            size='small'
            onChange={(e, league) => handleChangeLeague(league)}
            readOnly={!!competition}
            renderInput={(params) => <TextField {...params} label="League" />}
          />
          {/* <TextField
            name="name"
            variant="outlined"
            size="small"
            label="Season name"
            value={tempCompetition.currentSeason.name}
            onChange={handleChangeSeason}
          />
          <TextField
            name="startDate"
            variant="outlined"
            size="small"
            label="Start date"
            value={tempCompetition.currentSeason.startDate}
            onChange={handleChangeSeason}
          />
          <TextField
            name="endDate"
            variant="outlined"
            size="small"
            label="End date"
            value={tempCompetition.currentSeason.endDate}
            onChange={handleChangeSeason}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => onConfirm(tempCompetition)} disabled={isDisabled}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManageCompetitionDialog;
