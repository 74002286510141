import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import hasPermissions from "../../../utils/hasPermissions";
import { APIS, PERMISSIONS, SPORTS } from "../../../utils/constants";
import { deleteCompetition, getCompetitions, postCompetition, putCompetition } from "../../../api/competitions";
import ConfirmationDialog from "../../common/confirmationDialog";
import ManageCompetitionDialog from "../manageCompetitionDialog";

const createColumns = (handleOpenDialog, handleOpenConfirmation) => {
  return [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      sortable: false,
    },
    {
      field: "leagueName",
      headerName: "League",
      flex: 2,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Competition",
      flex: 2,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (row) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_COMPETITIONS)}
            onClick={() => handleOpenDialog(row.row)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_COMPETITIONS)}
            onClick={() => handleOpenConfirmation(row.row)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
};

export default function CompetitionTab() {
  const [competitions, setCompetitions] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [activeCompetition, setActiveCompetition] = useState(null);
  const [sortOptions, setSortOptions] = useState([
    { field: "name", sort: "asc" },
  ]);
  const [filters, setFilters] = useState({
    leagueName: "",
    name: "",
  });
  const [tempFilters, setTempFilters] = useState({
    leagueName: "",
    name: "",
  });

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      filterParams.push({ key, value: filters[key] });
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sortParams = [
      { key: "sortBy", value: sortOptions?.[0]?.field || "name" },
      { key: "sortDirection", value: sortOptions?.[0]?.sort || "asc" },
    ];
    const sourceParams = [
      { key: "source", value: APIS.INTERNAL },
      { key: "sportId", value: SPORTS.CRICKET },
    ];
    return [...filterParams, ...pageParams, ...sortParams, ...sourceParams];
  }, [filters, page, pageSize, sortOptions]);

  const fetchCompetitionList = useCallback(async () => {
    const queryParams = createParams();
    const competitions = await getCompetitions(
      queryParams
    );
    setCompetitions(competitions.data);
    setTotal(competitions.total);
  }, [createParams]);

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleOpenDialog = useCallback((item) => {
    setActiveCompetition(item);
    setIsDialogOpen(true);
  }, []);

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const handleSortModelChange = useCallback((sortModel) => {
    setPage(0);
    setSortOptions([...sortModel]);
  }, []);

  const handleResetFilters = useCallback(async () => {
    setFilters({ leagueName: "", name: "" });
    setTempFilters({
      leagueName: "",
      name: "",
    });
    setPage(0);
    await fetchCompetitionList();
  }, [fetchCompetitionList]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
    setIsConfirmationOpen(false);
    setActiveCompetition(null);
  }, []);

  const handleAddCompetition = useCallback(async (competition) => {
    await postCompetition(competition);
    await fetchCompetitionList();
    handleClose();
  }, [handleClose, fetchCompetitionList]);

  const handleEditCompetition = useCallback(async (competition) => {
    const data = { name: competition.name };
    const id = data.id;
    await putCompetition(id, data);
    await fetchCompetitionList();
    handleClose();
  }, [handleClose, fetchCompetitionList]);

  const handleOpenConfirmation = useCallback((item) => {
    setActiveCompetition(item);
    setIsConfirmationOpen(true);
  }, []);

  const handleDeleteConfirmation = useCallback(async () => {
    await deleteCompetition(activeCompetition.id);
    await fetchCompetitionList();
    handleClose();
  }, [fetchCompetitionList, handleClose, activeCompetition]);

  const handleConfirm = useCallback((competition) => {
    if (competition.id) {
      return handleEditCompetition(competition);
    }
    handleAddCompetition(competition);
  }, [handleAddCompetition, handleEditCompetition]);

  useEffect(() => {
    fetchCompetitionList();
  }, [fetchCompetitionList]);

  const columns = useMemo(() => {
    return createColumns(handleOpenDialog, handleOpenConfirmation);
  }, [handleOpenDialog, handleOpenConfirmation]);
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Competitions</h1>
      <Button
        variant="contained"
        color="primary"
        disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_COMPETITIONS)}
        onClick={() => handleOpenDialog()}
      >
        Add new competition
      </Button>
      {isConfirmationOpen && <ConfirmationDialog
        open={isConfirmationOpen}
        title={"Confirm"}
        content={"Do you want to delete this competition?"}
        onClose={handleClose}
        onConfirm={handleDeleteConfirmation}
      />}
      {isDialogOpen && <ManageCompetitionDialog open={isDialogOpen} onConfirm={handleConfirm} onClose={handleClose} competition={activeCompetition} />}
      <div>
        {competitions && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "12px",
                height: "100px",
              }}
            >
              filter by
              <TextField
                name="leagueName"
                variant="outlined"
                size="small"
                label="League"
                value={tempFilters.leagueName}
                onChange={(event) => handleFiltersChange(event)}
              />
              <TextField
                name="name"
                variant="outlined"
                size="small"
                label="Competition"
                value={tempFilters.name}
                onChange={(event) => handleFiltersChange(event)}
              />
              <Button variant="contained" onClick={() => handleSearchClick()}>
                Search
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleResetFilters()}
              >
                Reset
              </Button>
            </div>
            <Box sx={{ height: "700px", width: "100%" }}>
              <DataGrid
                rows={competitions}
                columns={columns}
                rowCount={total}
                pageSize={pageSize}
                page={page}
                paginationMode="server"
                onPageChange={setPage}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={[25, 50, 100]}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                disableSelectionOnClick
                disableColumnFilter
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          </div>
        )}
      </div>
    </div>
  );
}
