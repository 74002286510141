import React, { useCallback, useState } from 'react';
import { TextField } from '@mui/material';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { APIS, SPORTS } from '../../../utils/constants';

const ManageLeagueDialog = ({ open, onConfirm, onClose, league }) => {
  const [tempLeague, setTempLeague] = useState(league || { name: '', source: APIS.INTERNAL, sportId: SPORTS.CRICKET });
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setTempLeague({ ...tempLeague, [name]: value });
  }, [tempLeague]);
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{(league && league.name) || 'New league'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', minWidth: '450px' }}>
          <TextField
            name="name"
            variant="outlined"
            size="small"
            label="Name"
            value={tempLeague.name}
            onChange={handleChange}
            sx={{ marginTop: '10px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => onConfirm(tempLeague)} disabled={!tempLeague.name}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManageLeagueDialog;
