import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import hasPermissions from "../../../utils/hasPermissions";
import { PERMISSIONS } from "../../../utils/constants";
import { deleteLeague, fetchLeagues, postLeague, putLeague } from "../../../api/leagues";
import ManageLeagueDialog from "../manageLeagueDialog";
import ConfirmationDialog from "../../common/confirmationDialog";
import { APIS, SPORTS } from "../../../utils/constants";

const createColumns = (handleOpenDialog, handleOpenConfirmation) => {
  return [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      sortable: false,
    },
    {
      field: "name",
      headerName: "League",
      flex: 2,
      sortable: false,
    },
    {
      field: "urlSegment",
      headerName: "Url",
      flex: 2,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      sortable: false,
      renderCell: (row) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_LEAGUES)}
            onClick={() => handleOpenDialog(row.row)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_LEAGUES)}
            onClick={() => handleOpenConfirmation(row.row)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
};

export default function LeaguesTab() {
  const [leagues, setLeagues] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [activeLeague, setActiveLeague] = useState(null);
  const [filters, setFilters] = useState({ name: "" });
  const [tempFilters, setTempFilters] = useState({ name: "" });

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      filterParams.push({ key, value: filters[key] });
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sourceParams = [
      { key: "source", value: APIS.INTERNAL },
      { key: "sportId", value: SPORTS.CRICKET },
    ];
    return [...filterParams, ...pageParams, ...sourceParams];
  }, [filters, page, pageSize]);

  const fetchLeagueList = useCallback(async () => {
    const queryParams = createParams();
    const leagues = await fetchLeagues(
      queryParams
    );
    setLeagues(leagues.data);
    setTotal(leagues.total);
  }, [createParams]);

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleOpenDialog = useCallback((item) => {
    setActiveLeague(item);
    setIsDialogOpen(true);
  }, []);

  const handleOpenConfirmation = useCallback((item) => {
    setActiveLeague(item);
    setIsConfirmationOpen(true);
  }, []);

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const handleResetFilters = useCallback(() => {
    setFilters({ name: "" });
    setTempFilters({ name: "" });
    setPage(0);
    fetchLeagueList();
  }, [fetchLeagueList]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
    setIsConfirmationOpen(false);
    setActiveLeague(null);
  }, []);

  const handleAddLeague = useCallback(async (league) => {
    await postLeague(league);
    await fetchLeagueList();
    handleClose();
  }, [handleClose, fetchLeagueList]);

  const handleEditLeague = useCallback(async (league) => {
    const data = { name: league.name };
    const id = league.id;
    await putLeague(id, data);
    await fetchLeagueList();
    handleClose();
  }, [handleClose, fetchLeagueList]);

  const handleDeleteLeague = useCallback(async () => {
    await deleteLeague(activeLeague.id);
    await fetchLeagueList();
    handleClose();
  }, [fetchLeagueList, handleClose, activeLeague]);

  const handleConfirm = useCallback((league) => {
    if (league.id) {
      return handleEditLeague(league);
    }
    handleAddLeague(league);
  }, [handleAddLeague, handleEditLeague]);

  useEffect(() => {
    fetchLeagueList();
  }, [fetchLeagueList]);

  const columns = useMemo(() => {
    return createColumns(handleOpenDialog, handleOpenConfirmation);
  }, [handleOpenDialog, handleOpenConfirmation]);
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Leagues</h1>
      <Button
        variant="contained"
        color="primary"
        disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_LEAGUES)}
        onClick={() => handleOpenDialog()}
      >
        Add new league
      </Button>
      {isConfirmationOpen && <ConfirmationDialog
        open={isConfirmationOpen}
        title={"Confirm"}
        content={"Do you want to delete this league?"}
        onClose={handleClose}
        onConfirm={handleDeleteLeague}
      />}
      {isDialogOpen && <ManageLeagueDialog open={isDialogOpen} onConfirm={handleConfirm} onClose={handleClose} league={activeLeague} />}
      <div>
        {leagues && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "12px",
                height: "100px",
              }}
            >
              filter by
              <TextField
                name="name"
                variant="outlined"
                size="small"
                label="League"
                value={tempFilters.name}
                onChange={(event) => handleFiltersChange(event)}
              />
              <Button variant="contained" onClick={() => handleSearchClick()}>
                Search
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleResetFilters()}
              >
                Reset
              </Button>
            </div>
            <Box sx={{ height: "700px", width: "100%" }}>
              <DataGrid
                rows={leagues}
                columns={columns}
                rowCount={total}
                pageSize={pageSize}
                page={page}
                paginationMode="server"
                onPageChange={setPage}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={[25, 50, 100]}
                sortingMode="server"
                disableSelectionOnClick
                disableColumnFilter
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          </div>
        )}
      </div>
    </div>
  );
}
