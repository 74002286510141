import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useCallback, useState } from "react";
import TypeController from "./typeController/TypeController";
import { INTERNAL_MANAGE_TYPES, PERMISSIONS, SPORTS } from "../../utils/constants";
import hasPermissions from "../../utils/hasPermissions";

export default function CustomApiBlock() {
  const [value, setValue] = useState("0");

  const typeKeys = [
    { name: INTERNAL_MANAGE_TYPES.MATCHES, permissions: [] },
    { name: INTERNAL_MANAGE_TYPES.COMPETITIONS, permissions: [PERMISSIONS.ALLOW_READ_COMPETITIONS] },
    { name: INTERNAL_MANAGE_TYPES.LEAGUES, permissions: [] },
    { name: INTERNAL_MANAGE_TYPES.TEAMS, permissions: [PERMISSIONS.ALLOW_READ_COMPETITORS] }];

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          centered
        >
          {typeKeys.map((type, index) => (
            hasPermissions(type.permissions) && <Tab key={type.name} label={type.name} value={index.toString()} />
          ))}
        </TabList>
      </Box>
      {typeKeys.map((type, index) => (
        <TabPanel
          key={index}
          value={index.toString()}
        >
          <TypeController type={type.name} sport={SPORTS.CRICKET} />
        </TabPanel>
      ))}
    </TabContext>
  );
}
