import { toast } from "react-hot-toast";

export async function execute(func) {
    try {
        const result = await func();
        return result?.data;
    } catch (e) {
        e?.response?.data?.errors?.forEach((item) => toast.error(item.message));
        throw e;
    }
}