import React, { useCallback, useMemo, useState } from 'react';
import { FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { APIS, SPORTS } from '../../../utils/constants';

const ManageCompetitorDialog = ({ open, onConfirm, onClose, competitor }) => {
  const [tempTeam, setTempTeam] = useState(competitor || { name: '', country: '', countryCode: '', gender: 'Men', abbreviation: '', isDeactivated: false, source: APIS.INTERNAL, sport: SPORTS.CRICKET });
  const handleChange = useCallback((e) => {
    const { name } = e.target;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setTempTeam({ ...tempTeam, [name]: value });
  }, [tempTeam]);

  const isDisabled = useMemo(() => {
    return !tempTeam.name || !tempTeam.abbreviation || !tempTeam.gender;
  }, [tempTeam]);
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{(competitor && competitor.name) || 'New team'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', minWidth: '450px' }}>
          <TextField
            name="name"
            variant="outlined"
            size="small"
            label="Name"
            value={tempTeam.name}
            onChange={handleChange}
            sx={{ marginTop: '10px' }}
          />
          <TextField
            name="abbreviation"
            variant="outlined"
            size="small"
            label="Abbreviation"
            value={tempTeam.abbreviation}
            onChange={handleChange}
            sx={{ marginTop: '10px' }}
          />
          <FormControl size='small'>
            <InputLabel id="select">Gender</InputLabel>
            <Select
              name='gender'
              labelId="select"
              id="select"
              value={tempTeam.gender}
              label="gender"
              onChange={handleChange}
              readOnly={!!competitor}
              size='small'
            >
              <MenuItem value='Men'>Men</MenuItem>
              <MenuItem value='Women'>Women</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="country"
            variant="outlined"
            size="small"
            label="Country"
            value={tempTeam.country}
            onChange={handleChange}
            disabled={competitor}
            sx={{ marginTop: '10px' }}
          />
          <TextField
            name="countryCode"
            variant="outlined"
            size="small"
            label="Country code"
            value={tempTeam.countryCode}
            onChange={handleChange}
            disabled={competitor}
            sx={{ marginTop: '10px' }}
          />
          {competitor && <FormGroup>
            <FormControlLabel label="Is Deactivated" control={
              <Switch
                name="isDeactivated"
                onChange={handleChange}
                value={tempTeam.isDeactivated}
                checked={tempTeam.isDeactivated}
              />
            }>
            </FormControlLabel>
          </FormGroup>}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => onConfirm(tempTeam)} disabled={isDisabled}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManageCompetitorDialog;
