export default function hasPermissions(componentPermission, anyPermission) {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) {
    return false;
  }
  const userPermissions = new Set(user.permissions);
  if (Array.isArray(componentPermission)) {
    const commonItems = componentPermission.filter(item => userPermissions.has(item));
    if (commonItems.length === componentPermission.length || (anyPermission && commonItems.length)) {
      return true;
    }
    return false;
  } else {
    const permission = userPermissions.has(componentPermission);
    return permission;
  }
}