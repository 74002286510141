
import { applyQueryParams } from "../utils/request";
import instance from "./api";
import { execute } from "./response";

const fetchLeagues = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/leagues`;
  return execute(() => instance.get(applyQueryParams(endpoint, params)));
};

const postLeague = async (data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/leagues`;
  return execute(() => instance.post(applyQueryParams(endpoint), data));
};

const putLeague = async (id, data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/leagues/${id}`;
  return execute(() => instance.put(applyQueryParams(endpoint), data));
};

const deleteLeague = async (id) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/leagues/${id}`;
  return execute(() => instance.delete(applyQueryParams(endpoint)));
};

const fetchAllLeaguesBySport = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/leagues`;
  const res = await instance.get(
    applyQueryParams(endpoint, [...params, {
      key: 'take',
      value: 10_000
    }, {
      key: 'skip',
      value: 0
    }])
  );
  return res.data?.data;
};

const enableLeague = async (projectID, leagueID) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/projects/${projectID}/leagues/${leagueID}/enable`;
  const res = await instance.post(endpoint);
  return res.data;
};

const disableLeague = async (projectID, leagueID) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/projects/${projectID}/leagues/${leagueID}/disable`;
  const res = await instance.post(endpoint);
  return res.data;
};

export { fetchLeagues, fetchAllLeaguesBySport, enableLeague, disableLeague, postLeague, putLeague, deleteLeague };
