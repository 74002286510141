import React from 'react';
import { INTERNAL_MANAGE_TYPES } from '../../../utils/constants';
import CompetitionTab from '../competitionTab';
import { CompetitorsTab } from '../competitorsTab';
import { EventTab } from '../eventTab';
import LeaguesTab from '../leaguesTab';

const TypeController = ({ type, sport }) => {
  return (
    <>
      {type === INTERNAL_MANAGE_TYPES.LEAGUES && <LeaguesTab sport={sport} />}
      {type === INTERNAL_MANAGE_TYPES.COMPETITIONS && <CompetitionTab sport={sport} />}
      {type === INTERNAL_MANAGE_TYPES.TEAMS && <CompetitorsTab sport={sport} />}
      {type === INTERNAL_MANAGE_TYPES.MATCHES && <EventTab sport={sport} />}
    </>
  );
}

export default TypeController;
