import { applyQueryParams } from "../utils/request";
import instance from "./api";
import { execute } from "./response";

const getEvents = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/events`;
  return execute(() => instance.get(applyQueryParams(endpoint, params)));
};

const postEvent = async (data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/events`;
  return execute(() => instance.post(applyQueryParams(endpoint), data));
};

const putEvent = async (id, data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/events/${id}`;
  return execute(() => instance.put(applyQueryParams(endpoint), data));
};

const deleteEvent = async (id) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/events/${id}`;
  return execute(() => instance.delete(applyQueryParams(endpoint)));
};

export { getEvents, postEvent, putEvent, deleteEvent };
