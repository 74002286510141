import React, { useCallback, useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Autocomplete } from '@mui/material';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { APIS, SPORTS } from '../../../utils/constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { getCompetitions, } from "../../../api/competitions";
import {
  fetchAllCompetitors,
} from "../../../api/competitors";
import {
  fetchAllVenues,
} from "../../../api/venues";
import { useMemo } from 'react';

const statuses = ['Unknown', 'Upcoming', 'Live', 'Finished', 'Abandoned', 'Interrupted', 'Postponed', 'Cancelled', 'NotPlayed', 'Queued'];

const ManageEventDialog = ({ open, onConfirm, onClose, event }) => {
  const [venue, setVenue] = useState(null);
  const [firstCompetitor, setFirstCompetitor] = useState(null);
  const [secondCompetitor, setSecondCompetitor] = useState(null);
  const [competition, setCompetition] = useState(null);

  const [competitorsOne, setCompetitorsOne] = useState([]);
  const [competitorsTwo, setCompetitorsTwo] = useState([]);
  const [venues, setVenues] = useState([]);
  const [competitions, setCompetitions] = useState([]);

  const [tempEvent, setTempEvent] = useState(event ||
  {
    venueId: null,
    competitionId: null,
    description: '',
    date: null,
    status: 'Unknown',
    city: '',
    place: '',
    competitorOneWinChance: 0,
    competitors: ['', ''],
    source: APIS.INTERNAL,
    sportId: SPORTS.CRICKET
  });
  const getCompetitors = useCallback(async (index, value) => {
    const params = [{ key: 'name', value: value || '' }, { key: 'sportId', value: SPORTS.CRICKET }];
    if (!value) {
      params.push({ key: 'take', value: 25 });
    }
    const competitors = await fetchAllCompetitors(params);
    if (index === null) {
      setCompetitorsOne(competitors.data);
      setCompetitorsTwo(competitors.data);
    } else {
      index === 0 ? setCompetitorsOne(competitors.data) : setCompetitorsTwo(competitors.data);
    }
  }, []);

  const getVenues = useCallback(async (value) => {
    const params = [{ key: 'name', value: value || '' }, { key: 'sportId', value: SPORTS.CRICKET }];
    if (!value) {
      params.push({ key: 'take', value: 25 });
    }
    const venues = await fetchAllVenues(params);
    setVenues(venues.data)
  }, []);

  const fetchCompetitions = useCallback(async (value) => {
    const params = [{ key: 'name', value: value || '' }, { key: 'sportId', value: SPORTS.CRICKET }];
    if (!value) {
      params.push({ key: 'take', value: 25 });
    }
    const competitions = await getCompetitions(params);
    setCompetitions(competitions.data)
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setTempEvent({ ...tempEvent, [name]: value });
  }, [tempEvent]);

  const handleChangeVenues = useCallback((venue) => {
    setVenue(venue);
    setTempEvent({ ...tempEvent, venueId: venue.id });
  }, [tempEvent]);

  const handleChangeCompetition = useCallback((competition) => {
    setCompetition(competition);
    setTempEvent({ ...tempEvent, competitionId: competition.id });
  }, [tempEvent]);

  const handleChangeCompetitors = useCallback((competitor, index) => {
    index === 0 ? setFirstCompetitor(competitor) : setSecondCompetitor(competitor);
    const newCompetitors = [...tempEvent.competitors];
    newCompetitors[index] = competitor.id;
    setTempEvent({ ...tempEvent, competitors: newCompetitors });
  }, [tempEvent]);

  const isDisabled = useMemo(() => {
    return !tempEvent.competitionId || !tempEvent.date || !tempEvent.status || !tempEvent.competitors[0] || !tempEvent.competitors[1];
  }, [tempEvent]);

  const setupFields = useCallback(async () => {
    if (!event) {
      return;
    }
    const [competition, firstCompetitor, secondCompetitor, venue] = await Promise.all([
      getCompetitions([{ key: 'id', value: event.competitionId }, { key: 'source', value: APIS.INTERNAL }, { key: 'sportId', value: SPORTS.CRICKET }]),
      fetchAllCompetitors([{ key: 'id', value: event.competitors[0].id }, { key: 'sportId', value: SPORTS.CRICKET }]),
      fetchAllCompetitors([{ key: 'id', value: event.competitors[1].id }, { key: 'sportId', value: SPORTS.CRICKET }]),
      fetchAllVenues([{ key: 'id', value: event.venueId }, { key: 'sportId', value: SPORTS.CRICKET }])
    ]);
    competition.data.length === 1 && setCompetition(competition.data[0]);
    firstCompetitor.data.length === 1 && setFirstCompetitor(firstCompetitor.data[0]);
    secondCompetitor.data.length === 1 && setSecondCompetitor(secondCompetitor.data[0]);
    venue.data.length === 1 && setVenue(venue.data[0]);
  }, [event]);

  useEffect(() => {
    getCompetitors(null);
    getVenues();
    fetchCompetitions();
    setupFields();
  }, [getCompetitors, getVenues, fetchCompetitions, setupFields]);

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{(event && event.name) || 'New event'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', minWidth: '450px', minHeight: '500px' }}>
          <Autocomplete
            sx={{ marginTop: '10px' }}
            disablePortal
            options={competitions}
            filterOptions={(x) => x}
            getOptionLabel={(option) => `${option.name} [${option.source}] [${option.id}] `}
            value={competition}
            isOptionEqualToValue={(option, value) => option.name === value.name && option.id === value.id}
            noOptionsText="No items"
            size='small'
            onInputChange={(event, newValue) => fetchCompetitions(newValue)}
            onChange={(e, value) => handleChangeCompetition(value)}
            renderInput={(params) => <TextField {...params} label="Competition" />}
            readOnly={!!event}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                label="Date"
                value={tempEvent.newValue}
                onChange={(newValue) => handleChange({ target: { name: 'date', value: newValue } })}
                slotProps={{ textField: { size: 'small' } }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <FormControl size='small'>
            <InputLabel id="select">Status</InputLabel>
            <Select
              name='status'
              labelId="select"
              id="select"
              value={tempEvent.status}
              label="status"
              onChange={handleChange}
              size='small'
            >
              {statuses.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </Select>
          </FormControl>
          <Autocomplete
            disablePortal
            options={competitorsOne}
            filterOptions={(x) => x}
            getOptionLabel={(option) => `${option.name} [${option.source}] [${option.id}]`}
            value={firstCompetitor}
            isOptionEqualToValue={(option, value) => option.name === value.name && option.sport === value.sport && option.source === value.source && option.id === value.id}
            noOptionsText="No items"
            size='small'
            onInputChange={(event, newValue) => getCompetitors(0, newValue)}
            onChange={(e, value) => handleChangeCompetitors(value, 0)}
            renderInput={(params) => <TextField {...params} label="Competitor one" />}
            readOnly={!!event}
          />
          <Autocomplete
            disablePortal
            options={competitorsTwo}
            filterOptions={(x) => x}
            getOptionLabel={(option) => `${option.name} [${option.source}] [${option.id}]`}
            value={secondCompetitor}
            isOptionEqualToValue={(option, value) => option.name === value.name && option.sport === value.sport && option.source === value.source && option.id === value.id}
            noOptionsText="No items"
            size='small'
            onInputChange={(event, newValue) => getCompetitors(1, newValue)}
            onChange={(e, value) => handleChangeCompetitors(value, 1)}
            renderInput={(params) => <TextField {...params} label="Competitor two" />}
            readOnly={!!event}
          />
          <TextField
            type='number'
            name="competitorOneWinChance"
            variant="outlined"
            size="small"
            label="Competitor one win chance"
            value={tempEvent.competitorOneWinChance}
            onChange={handleChange}
          />
          <TextField
            name="city"
            variant="outlined"
            size="small"
            label="City"
            value={tempEvent.city}
            onChange={handleChange}
          />
          <TextField
            name="place"
            variant="outlined"
            size="small"
            label="Place"
            value={tempEvent.place}
            onChange={handleChange}
          />
          <TextField
            name="description"
            variant="outlined"
            size="small"
            label="Description"
            value={tempEvent.description}
            onChange={handleChange}
          />
          <Autocomplete
            disablePortal
            options={venues}
            filterOptions={(x) => x}
            getOptionLabel={(option) => `${option.name} [${option.sport}] [${option.city}]`}
            value={venue}
            isOptionEqualToValue={(option, value) => option.name === value.name && option.sport === value.sport && option.city === value.city}
            noOptionsText="No items"
            size='small'
            onInputChange={(event, newValue) => getVenues(newValue)}
            onChange={(e, value) => handleChangeVenues(value)}
            renderInput={(params) => <TextField {...params} label="Venue" />}
            readOnly={!!event}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => onConfirm(tempEvent)} disabled={isDisabled}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManageEventDialog;
