import { useMemo } from "react";
import hasPermissions from "../../utils/hasPermissions";

function PrivateComponent(props) {
  const { children } = props

  const permission = useMemo(() => {
    return hasPermissions(props.permission, props.anyPermission);
  }, [props.permission, props.anyPermission]);

  if (permission) {
    return children;
  }
  return null;
}

export default PrivateComponent;
